import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TokenTable from "~/components/TokenTable/TokenTable";
export const _frontmatter = {
  "menuLabel": "Border Width",
  "sortOrder": 5.2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Border Width`}</h1>
    <p className="p-large">Border widths emphasize elements by providing contrast against the background.</p>
    <p>{`We don't have semantic tokens for border width because there's already a small set of them to begin with. The majority of width usage should be `}<strong parentName="p">{`width-1`}</strong>{`.`}</p>
    <TokenTable tokenGroup="BorderWidth" mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      